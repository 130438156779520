<template>
  <div class="taskBox">
    <!-- 课程列表 -->
    <div class="courseList">
      <div class="tit">
        课程列表<el-button type="text" @click="dialogCoursesVisibleBtn"
          >+添加课程</el-button
        >
      </div>
      <!-- tree -->
      <el-tree :data="treeData" :props="defaultProps"></el-tree>
    </div>
    <!-- 课程任务安排列表 -->
    <div class="taskList">
      <div class="tit">课程任务安排列表</div>
      <div>
        <el-button @click="intelligentPlanBtn" type="primary" size="small"
          >智能排课</el-button
        >
        <el-button @click="dialogVisibleBtn('add')" type="primary" size="small"
          >手动排课</el-button
        >
      </div>
      <!-- 表格 -->
      <el-row style="margin-top: 20px">
        <el-table
          v-loading="loadingRight"
          border
          ref="tableData"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
        >
          <el-table-column label="上课时间" width="140">
            <template slot-scope="scope">
              {{ scope.row.schoolTime | dayFilters }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sysSchoolClassSessionName"
            label="上课节次"
            width="120"
          ></el-table-column>
          <el-table-column prop="crsExpPlanCode" label="科目" width="120">
            <template slot-scope="scope">
              <!-- {{ subjectTypeFilters(scope.row.sysSubjectDicId) }} -->
              {{ scope.row.sysSubjectDic?.sysDicName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="crsExpCourseName"
            label="课程"
          ></el-table-column>
          <el-table-column
            prop="sysOrgTeachPlaceRoomName"
            label="上课地点"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolClassName"
            label="上课班级"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="teacherName"
            label="任课老师"
            width="120"
          ></el-table-column>
          <el-table-column label="操作" show-overflow-tooltip width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="dialogVisibleBtn('update', scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="text"
                style="color: red"
                @click="deleteBtn(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalSize"
        ></el-pagination>
      </el-row>
    </div>
    <!-- 添加课程 弹框 -->
    <el-dialog
      title="添加课程"
      :visible.sync="dialogCoursesVisible"
      width="1000px"
    >
      <el-form :model="dialogCoursesVisibleParam" :inline="true" size="mini">
        <el-form-item label="编号/名称">
          <el-input
            v-model="dialogCoursesVisibleParam.code"
            @change="dialogCoursesVisibleParamChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="科目">
          <el-select
            v-model="dialogCoursesVisibleParam.sysSubjectDicId"
            @change="dialogCoursesVisibleParamChange"
          >
            <el-option
              v-for="item in $store.state.public_data.sysSubjectDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属年级">
          <el-cascader
            @change="dialogCoursesVisibleParamCascader"
            :options="$store.state.public_data.sysSemesterTermGradeDicList"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogCoursesResetBtn">重置</el-button>
          <el-button @click="dialogCoursesInquireBtn" type="primary"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-row>
        <el-table
          v-loading="dialogLoading"
          border
          ref="dialogCoursesVisibleTable"
          :data="dialogCoursesVisibleTable"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          height="340px"
          @selection-change="dialogHandleSelectionChange"
        >
          <el-table-column
            type="selection"
            :selectable="dialogSelectable"
            width="55"
          >
          </el-table-column>
          <el-table-column prop="name" label="课程名称"></el-table-column>
          <el-table-column prop="code" label="课程编号"></el-table-column>
          <el-table-column
            prop="sysSubjectDic.sysDicName"
            label="所属科目"
          ></el-table-column>
          <el-table-column label="关联实验">
            <template slot-scope="scope">
              {{ findExpNameFunc(scope.row.sysExperimentId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="schoolSectionDic.sysDicName"
            label="学段"
          ></el-table-column>
          <el-table-column
            prop="sysSchoolClassGradeName"
            label="年级"
          ></el-table-column>
          <el-table-column label="演示实验">
            <template slot-scope="scope">
              <span v-if="scope.row.demoExpStatus == 1">必做</span>
              <span v-else-if="scope.row.demoExpStatus == 2">选做</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column prop="groupExpStatus" label="分组实验">
            <template slot-scope="scope">
              <span v-if="scope.row.groupExpStatus == 1">必做</span>
              <span v-else-if="scope.row.groupExpStatus == 2">选做</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="dialogHandleSizeChange"
          @current-change="dialogHandleCurrentChange"
          :current-page="dialogCoursesVisiblePage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="dialogCoursesVisiblePage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dialogCoursesVisiblePage.totalSize"
        ></el-pagination>
        <div style="display: flex; justify-content: end">
          <el-button @click="cancelDialogCoursesVisibleBtn">返回</el-button>
          <el-button @click="addDialogCoursesVisibleBtn" type="primary"
            >添加</el-button
          >
        </div>
      </el-row>
    </el-dialog>
    <!-- 手动/编辑 弹框 -->
    <el-dialog
      :title="dialogVisiblePlanTit"
      :visible.sync="dialogVisiblePlan"
      width="500px"
    >
      <el-form
        :model="dialogVisiblePlanData"
        ref="dialogVisiblePlanData"
        hide-required-asterisk
        label-width="80px"
      >
        <el-form-item label="课程" prop="crsExpCourseId">
          <el-select
            style="width: 380px"
            v-model="dialogVisiblePlanData.crsExpCourseId"
            @change="crsExpCourseIdChangFunc"
          >
            <el-option
              v-for="item in conditionPlanList.crsExpCourseIdList"
              :key="item.crsExpCourseId"
              :label="item.crsExpCourseName"
              :value="item.crsExpCourseId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目" prop="sysSubjectDicId">
          <el-select
            style="width: 380px"
            v-model="dialogVisiblePlanData.sysSubjectDicId"
            disabled
          >
            <el-option
              v-for="item in $store.state.public_data.sysSubjectDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课日期" prop="schoolTime">
          <el-date-picker
            style="width: 380px"
            popper-class="no-atTheMoment"
            v-model="dialogVisiblePlanData.schoolTime"
            class="elPicker"
            type="datetime"
            format="yyyy-MM-dd"
            placeholder="上课日期"
            value-format="timestamp"
            default-time="00:00:00"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="上课节次" prop="sysSchoolClassSessionId">
          <el-select
            style="width: 380px"
            v-model="dialogVisiblePlanData.sysSchoolClassSessionId"
          >
            <el-option
              v-for="item in $store.state.public_data.sysSessionDataDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课地点" prop="sysOrgSchoolRoomId">
          <el-select
            style="width: 380px"
            filterable
            v-model="dialogVisiblePlanData.sysOrgSchoolRoomId"
          >
            <el-option
              v-for="item in conditionPlanList.sysOrgSchoolRoomIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课班级" prop="sysOrgSchoolClassId">
          <el-select
            style="width: 380px"
            v-model="dialogVisiblePlanData.sysOrgSchoolClassId"
          >
            <el-option
              v-for="item in conditionPlanList.sysOrgSchoolClassIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任课老师" prop="teacherId">
          <el-select
            style="width: 380px"
            v-model="dialogVisiblePlanData.teacherId"
          >
            <el-option
              v-for="item in conditionPlanList.teacherIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePlan = false">取 消</el-button>
        <el-button type="primary" @click="savePlanBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置排课规则弹框 -->
    <el-dialog
      title="设置排课规则"
      :visible.sync="dialogVisibleIntelligentPlan"
      width="600px"
    >
      <el-tabs tab-position="left" style="height: 400px; overflow-y: auto">
        <el-tab-pane label="课时规则">
          <div style="height: 400px; overflow-y: auto">
            <el-form
              ref="intelligentClassHourData"
              :model="intelligentClassHourData"
              label-width="100px"
            >
              <el-form-item label="周课时数">
                <el-input
                  min="1"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  v-model.number="intelligentClassHourData.weekLessonNum"
                  @change="addOrUpdateClassHourFunc"
                ></el-input>
              </el-form-item>
              <el-form-item label="上课星期选择">
                <el-checkbox-group
                  v-model="intelligentClassHourData.dayOfWeekList"
                  @change="addOrUpdateClassHourFunc"
                >
                  <el-checkbox :label="1">星期一</el-checkbox>
                  <el-checkbox :label="2">星期二</el-checkbox>
                  <el-checkbox :label="3">星期三</el-checkbox>
                  <el-checkbox :label="4">星期四</el-checkbox>
                  <el-checkbox :label="5">星期五</el-checkbox>
                  <el-checkbox :label="6">星期六</el-checkbox>
                  <el-checkbox :label="7">星期日</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="上课节次选择">
                <el-checkbox-group
                  v-model="intelligentClassHourData.sysSchoolClassSessionId"
                  @change="addOrUpdateClassHourFunc"
                >
                  <el-checkbox
                    v-for="item in $store.state.public_data
                      .sysSessionDataDicList"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="场地规则">
          <div style="height: 400px; overflow-y: auto">
            <div style="display: flex; justify-content: end">
              <el-button type="text" @click="addRuleBtn">+添加规则</el-button>
            </div>
            <!-- style="" -->
            <div
              v-for="(val, i) in intelligentRuleTeachPlaceRoomData"
              :key="i"
              style="
                display: flex;
                align-items: center;
                padding: 20px 10px 0 10px;
                margin-bottom: 20px;
                background: #f2f2f2;
              "
            >
              <el-form label-width="70px" :model="val">
                <div style="display: flex">
                  <el-form-item label="科目" style="width: 50%">
                    <el-select
                      style="width: 100%"
                      v-model="val.sysSubjectDicId"
                      @change="
                        changSysSubjectDicId(val.sysSubjectDicId, val, i)
                      "
                    >
                      <el-option
                        v-for="item in $store.state.public_data
                          .sysSubjectDicList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="课程" style="width: 50%">
                    <el-select
                      @change="addRuleTeachPlaceRoomFunc"
                      style="width: 100%"
                      v-model="val.crsExpCourseId"
                    >
                      <el-option
                        v-for="item in val.crsExpCourseIdList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="指定地点">
                  <el-select
                    style="width: 100%"
                    v-model="val.sysOrgSchoolRoomId"
                    @change="addRuleTeachPlaceRoomFunc"
                  >
                    <el-option
                      v-for="item in val.sysOrgSchoolRoomIdList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-button
                type="text"
                style="color: #d9001b; margin-left: 10px; margin-bottom: 18px"
                @click="deleteRuleTeachPlaceRoomBtn(val, i)"
                >移除</el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="display: flex; justify-content: end; margin-top: 20px">
        <el-button @click="dialogVisibleIntelligentPlan = false"
          >取消</el-button
        >
        <el-button type="primary" @click="automaticPlanBtn"
          >按规则排课</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
// this.$emit('saveSuccess')
import { debounce, throttle } from '@/utils/util.js'
import dayjs from 'dayjs'
import {
  getCoursePlanList,
  addCoursePlan,
  getExpPagePlan,
  getClassHour,
  getRuleTeachPlaceRoom,
  deleteRuleTeachPlaceRoom,
  addOrUpdateClassHour,
  getTeacher,
  getSchoolClass,
  addRuleTeachPlaceRoom,
  automaticPlan,
  manualPlan,
  updatePlan,
  deletePlan,
  getTeachPlaceRoom,
  getPagePlan,
} from '@/api/courseStart/scheduleLessonsManage.js'
import { getLabInfoFormMsgApi } from '@/api/standard/menuManagement.js'
import { getSchoolRoomListApi } from '@/api/mechanism/schoolRoomManage.js'
import { Loading } from 'element-ui'
export default {
  name: 'teacherArrangement',
  data() {
    return {
      crsOpenPlanId: '',
      sysOrgSchoolGradeId: '',
      // 课程列表
      treeData: [],
      defaultProps: {
        children: 'children',
      },
      // 添加课程 弹框
      dialogCoursesVisible: false,
      dialogCoursesVisibleParam: {
        code: '',
        sysSubjectDicId: '',
        sysSchoolClassGradeId: '',
      },
      dialogCoursesVisibleCondition: {},
      dialogLoading: false,
      dialogCoursesVisibleTable: [],
      dialogCoursesVisiblePage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      dialogHandleSelection: null,
      expList: [],
      // 课程任务列表
      loadingRight: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 智能排课
      dialogVisibleIntelligentPlan: false,
      // 课时规则
      intelligentClassHourData: {
        dayOfWeekList: [],
        sysSchoolClassSessionId: [],
        weekLessonNum: '',
      },
      // 场地规则
      intelligentRuleTeachPlaceRoomData: [],
      // 手动排课/编辑 弹框 内容
      dialogVisiblePlan: false,
      dialogVisiblePlanType: 'add',
      dialogVisiblePlanTit: '手动排课',
      dialogVisiblePlanData: {},
      conditionPlanList: {},
      dialogVisibleCrsOpenPlanLessonId: '',
    }
  },
  async created() {
    this.crsOpenPlanId = Number(this.$route.query.crsOpenPlanId)
    this.sysOrgSchoolGradeId = Number(this.$route.query.sysOrgSchoolGradeId)
    this.getPagePlanFunc()
    this.getCoursePlanListFunc()
  },
  mounted() {},

  methods: {
    // 课程列表---
    // 查询开课计划课程安排
    getCoursePlanListFunc() {
      getCoursePlanList(this.crsOpenPlanId)
        .then((res) => {
          if (res.success) {
            this.conditionPlanList.crsExpCourseIdList = JSON.parse(
              JSON.stringify(res.data)
            )
            console.log(
              'this.conditionPlanList.crsExpCourseIdList',
              this.conditionPlanList.crsExpCourseIdList
            )
            // 按学科处理成tree
            this.treeData = this.$store.state.public_data.sysSubjectDicList.map(
              (e) => ({
                ...e,
                children: [],
              })
            )
            this.treeData = this.treeData.map((item) => {
              res.data.forEach((e) => {
                if (e.sysSubjectDicId === item.value) {
                  item.children.push({
                    value: e.crsExpCourseId,
                    label: e.crsExpCourseName,
                  })
                }
              })
              return item
            })

            this.treeData = this.treeData.filter((e) => e.children.length != 0)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    // +添加课程
    dialogCoursesVisibleBtn() {
      this.dialogCoursesVisibleParam = {
        code: '',
        sysSubjectDicId: '',
        sysSchoolClassGradeId: '',
      }
      this.dialogCoursesVisiblePage.pageIndex = 1
      this.dialogCoursesVisibleParamChange()
      this.fetchAllExpList()
      this.dialogCoursesVisible = true
    },
    fetchAllExpList() {
      let params = {
        categoryId: 0,
        experimentCode: '',
        experimentName: '',
        subjectId: 0,
      }
      getLabInfoFormMsgApi(1, 10000, params).then((res) => {
        // console.log(res,"res")
        this.expList = res.data
      })
    },
    findExpNameFunc(id) {
      if (this.expList.length > 0) {
        return this.expList.find((it) => it.experimentId === id).experimentName
      }
    },
    // 添加课程条件修改
    dialogCoursesVisibleParamChange() {
      let conditionObj = JSON.parse(
        JSON.stringify(this.dialogCoursesVisibleParam)
      )
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getExpPagePlanFunc(conditionObj)
    },
    // 联级处理
    dialogCoursesVisibleParamCascader(val) {
      console.log('联级处理', val)
      if (val && val.length >= 2) {
        this.dialogCoursesVisibleParam.sysSchoolClassGradeId = val[1]
      } else {
        this.dialogCoursesVisibleParam.sysSchoolClassGradeId = ''
      }
      this.dialogCoursesVisiblePage.pageIndex = 1
      this.dialogCoursesVisibleParamChange()
    },
    // 添加课程-重置
    dialogCoursesResetBtn() {
      this.dialogCoursesVisibleParam = {
        code: '',
        sysSubjectDicId: '',
        sysSchoolClassGradeId: '',
      }
      this.dialogCoursesVisiblePage.pageIndex = 1
      this.dialogCoursesVisibleParamChange()
    },
    // 添加课程-查询
    dialogCoursesInquireBtn() {
      this.dialogCoursesVisiblePage.pageIndex = 1
      this.dialogCoursesVisibleParamChange()
    },
    // 添加课程-分页查询实验课程
    getExpPagePlanFunc(data) {
      this.dialogHandleSelection = null
      this.dialogLoading = true
      getExpPagePlan(
        this.dialogCoursesVisiblePage.pageIndex,
        this.dialogCoursesVisiblePage.pageSize,
        this.sysOrgSchoolGradeId,
        data
      )
        .then((res) => {
          this.dialogLoading = false
          if (res.success) {
            this.dialogCoursesVisibleTable = res.data
            this.dialogCoursesVisiblePage.totalSize = res.total
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    dialogHandleSizeChange(val) {
      this.dialogCoursesVisiblePage.pageSize = val
      this.dialogCoursesVisiblePage.pageIndex = 1
      this.dialogCoursesVisibleParamChange()
    },
    dialogHandleCurrentChange(val) {
      this.dialogCoursesVisiblePage.pageIndex = val
      this.dialogCoursesVisibleParamChange()
    },
    // 多选处理
    dialogHandleSelectionChange(val) {
      this.dialogHandleSelection = val
    },
    dialogSelectable(row) {
      return true
    },
    // 添加课程-添加
    addDialogCoursesVisibleBtn() {
      if (
        this.dialogHandleSelection &&
        this.dialogHandleSelection.length != 0
      ) {
        let data = this.dialogHandleSelection.map((e) => ({
          crsExpCourseId: e.crsExpCourseId,
          sysSubjectDicId: e.sysSubjectDicId,
        }))
        addCoursePlan(this.crsOpenPlanId, data)
          .then((res) => {
            if (res.success) {
              this.getCoursePlanListFunc()
              this.cancelDialogCoursesVisibleBtn()
              this.$message.success('添加成功')
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {})
      } else {
        this.$message.info('先勾选课程')
      }
    },
    // 添加课程-取消
    cancelDialogCoursesVisibleBtn() {
      this.dialogHandleSelection = null
      this.dialogCoursesVisible = false
    },
    // 课程任务安排列表---
    // 智能排课
    async intelligentPlanBtn() {
      await this.getClassHourFunc()
      await this.getRuleTeachPlaceRoomFunc()
      this.dialogVisibleIntelligentPlan = true
    },
    // 查询课时规则安排
    async getClassHourFunc() {
      this.intelligentClassHourData = {
        dayOfWeekList: [],
        sysSchoolClassSessionId: [],
        weekLessonNum: 1,
      }
      await getClassHour(this.crsOpenPlanId)
        .then((res) => {
          if (res.success) {
            this.intelligentClassHourData = res.data
            const defaultValues = {
              weekLessonNum: 1,
              default: [],
            }
            for (const key in this.intelligentClassHourData) {
              if (this.intelligentClassHourData[key] == null) {
                this.intelligentClassHourData[key] =
                  defaultValues[key] || defaultValues.default
              }
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 查询场地规则安排
    async getRuleTeachPlaceRoomFunc() {
      this.intelligentRuleTeachPlaceRoomData = []
      await getRuleTeachPlaceRoom(this.crsOpenPlanId)
        .then(async (res) => {
          if (res.success) {
            // 根据选中的科目 获取相应的 老师 和 课程
            this.intelligentRuleTeachPlaceRoomData = await Promise.all(
              res.data.map(async (e) => {
                let item = {
                  ...e,
                  crsExpCourseIdList: [],
                  sysOrgSchoolRoomIdList: [],
                }
                if (item.sysSubjectDicId !== '') {
                  item.crsExpCourseIdList = this.treeData.find(
                    (item) => item.value === key
                  )?.children
                  let sysOrgSchoolRoomIdList =
                    await this.getSysOrgSchoolRoomIdListFunc(key)
                  item.sysOrgSchoolRoomIdList = sysOrgSchoolRoomIdList.map(
                    (evt) => ({
                      label: evt.name,
                      value: evt.sysOrgTeachPlaceRoomId,
                    })
                  )
                }
                return item
              })
            )
          } else {
            this.$message.error(res.msg)
          }
          this.$forceUpdate()
        })
        .catch((err) => {})
    },
    // 修改场地科目
    async changSysSubjectDicId(key, val, i) {
      val.crsExpCourseId = ''
      val.sysOrgSchoolRoomId = ''
      val.crsExpCourseIdList = []
      val.sysOrgSchoolRoomIdList = []
      // 根据选中的科目 获取相应的 老师 和 课程
      val.crsExpCourseIdList = this.treeData.find(
        (item) => item.value === key
      )?.children

      console.log('this.treeData', val.crsExpCourseIdList, this.treeData, key)
      val.sysOrgSchoolRoomIdList = (
        await this.getSysOrgSchoolRoomIdListFunc(key)
      ).map((e) => ({
        label: e.name,
        value: e.sysOrgTeachPlaceRoomId,
      }))
      this.$forceUpdate()
    },
    // 添加场地规则安排 - 节流
    addRuleTeachPlaceRoomFunc: throttle(function (row) {
      console.log(
        '添加场地规则安排',
        row,
        this.intelligentRuleTeachPlaceRoomData
      )
      if (
        this.intelligentRuleTeachPlaceRoomData.some(
          (e) =>
            e.sysSubjectDicId === '' ||
            e.sysOrgSchoolRoomId === '' ||
            e.crsExpCourseId === ''
        )
      ) {
        return
      }
      let data = this.intelligentRuleTeachPlaceRoomData.map((e) => ({
        crsExpCourseId: e.crsExpCourseId,
        sysOrgSchoolRoomId: e.sysOrgSchoolRoomId,
        sysSubjectDicId: e.sysSubjectDicId,
      }))
      addRuleTeachPlaceRoom(this.crsOpenPlanId, data)
        .then((res) => {
          console.log('addRuleTeachPlaceRoom', res)
          this.$forceUpdate()
        })
        .catch((err) => {})
    }),
    // 添加或更新课时规则安排 - 节流
    addOrUpdateClassHourFunc: throttle(function (row) {
      for (const key in this.intelligentClassHourData) {
        if (
          this.intelligentClassHourData[key] == '' ||
          this.intelligentClassHourData[key].length == 0
        ) {
          return
        }
      }
      addOrUpdateClassHour(this.crsOpenPlanId, this.intelligentClassHourData)
        .then((res) => {
          console.log('添加或更新课时规则安排', res)
          if (res.success) {
            this.$forceUpdate()
          } else {
            // this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    }),
    // 添加规则
    addRuleBtn() {
      this.intelligentRuleTeachPlaceRoomData.push({
        crsExpCourseId: '',
        sysOrgSchoolRoomId: '',
        sysSubjectDicId: '',
        sysOrgSchoolRoomIdList: [],
        crsExpCourseIdList: [],
      })
    },
    // 删除场地规则安排
    deleteRuleTeachPlaceRoomBtn: debounce(function (row, i) {
      console.log('删除场地规则安排', row)
      let flag = true
      for (const key in row) {
        if (row[key] != '') {
          flag = false
        }
      }
      this.intelligentRuleTeachPlaceRoomData.splice(i, 1)
      if (flag) return
      deleteRuleTeachPlaceRoom(this.crsOpenPlanId, row)
        .then((res) => {
          console.log('删除场地规则安排请求', res)
          this.$forceUpdate()
        })
        .catch((err) => {})
    }),
    // 自动排课
    automaticPlanBtn: debounce(function () {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '自动排课中',
      })
      automaticPlan(this.crsOpenPlanId)
        .then((res) => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          if (res.success) {
            this.dialogVisibleIntelligentPlan = false
            this.$forceUpdate()
            this.getPagePlanFunc()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    }, 300),
    // 手动排课按钮 /编辑
    async dialogVisibleBtn(key, val = null) {
      switch (key) {
        case 'add':
          this.dialogVisiblePlanTit = '手动排课'
          this.dialogVisibleCrsOpenPlanLessonId = ''
          this.dialogVisiblePlanData = {
            crsExpCourseId: '',
            schoolTime: '',
            sysOrgSchoolClassId: '',
            sysOrgSchoolRoomId: '',
            sysSchoolClassSessionId: '',
            sysSubjectDicId: '',
            teacherId: '',
          }
          break
        case 'update':
          this.dialogVisiblePlanTit = '排课编辑'
          this.dialogVisibleCrsOpenPlanLessonId = val.crsOpenPlanLessonId
          console.log('val', val)
          let {
            crsExpCourseId,
            schoolTime,
            sysOrgSchoolClassId,
            sysOrgTeachPlaceRoomId,
            sysSchoolClassSessionId,
            // sysSubjectDicId,
            teacherId,
            sysSubjectDic,
          } = val
          this.dialogVisiblePlanData = {
            crsExpCourseId,
            schoolTime,
            sysOrgSchoolClassId,
            sysOrgSchoolRoomId: sysOrgTeachPlaceRoomId,
            sysSchoolClassSessionId,
            sysSubjectDicId: sysSubjectDic.sysDicId,
            teacherId,
          }
          break
      }
      this.conditionPlanList.teacherIdList = []
      this.conditionPlanList.sysOrgSchoolRoomIdList = []
      if (val) {
        // 请求任课老师
        this.conditionPlanList.teacherIdList = (
          await this.getTeacherIdListFunc(
            this.dialogVisiblePlanData.sysSubjectDicId
          )
        ).map((e) => ({
          label: e.teacherName,
          value: e.teacherId,
        }))
        // 请求上课地点
        this.conditionPlanList.sysOrgSchoolRoomIdList = (
          await this.getSysOrgSchoolRoomIdListFunc(
            this.dialogVisiblePlanData.sysSubjectDicId
          )
        ).map((e) => ({
          label: e.name,
          value: e.sysOrgTeachPlaceRoomId,
        }))
        this.$forceUpdate()
      }
      this.dialogVisiblePlanType = key
      await this.getSchoolClassFunc()
      this.dialogVisiblePlan = true
      // this.$nextTick(() => {
      //   this.$refs.dialogVisibleData.clearValidate()
      // })
    },
    // 班级查询
    async getSchoolClassFunc() {
      await getSchoolClass(1, 10000, this.crsOpenPlanId, {})
        .then((res) => {
          if (res.success) {
            this.conditionPlanList.sysOrgSchoolClassIdList = res.data.map(
              (e) => ({
                label: e.sysOrgSchoolClassName,
                value: e.sysOrgSchoolClassId,
              })
            )
          } else {
            this.conditionPlanList.sysOrgSchoolClassIdList = []
          }
        })
        .catch((err) => {})
    },
    // 排课课程修改
    async crsExpCourseIdChangFunc(key) {
      let item = this.conditionPlanList.crsExpCourseIdList.find(
        (e) => e.crsExpCourseId == key
      )
      console.log('手动排课', key)
      // 赋值学科
      this.dialogVisiblePlanData.sysSubjectDicId = item.sysSubjectDicId
      // 清除上课地点清除任课老师
      this.dialogVisiblePlanData.teacherId = ''
      this.dialogVisiblePlanData.sysOrgSchoolRoomId = ''
      this.conditionPlanList.teacherIdList = []
      this.conditionPlanList.sysOrgSchoolRoomIdList = []
      // 请求任课老师
      this.conditionPlanList.teacherIdList = (
        await this.getTeacherIdListFunc(item.sysSubjectDicId)
      ).map((e) => ({
        label: e.teacherName,
        value: e.teacherId,
      }))
      // 请求上课地点
      this.conditionPlanList.sysOrgSchoolRoomIdList = (
        await this.getSysOrgSchoolRoomIdListFunc(item.sysSubjectDicId)
      ).map((e) => ({
        label: e.name,
        value: e.sysOrgTeachPlaceRoomId,
      }))
      console.log(
        'this.conditionPlanList.teacherIdList',
        this.conditionPlanList.teacherIdList
      )
      this.$forceUpdate()
    },
    // 请求任课老师
    async getTeacherIdListFunc(key) {
      try {
        const res = await getTeacher(1, 10000, this.crsOpenPlanId, {
          sysSubjectDicId: key,
        })
        console.log('请求任课老师', res)
        if (res.success) {
          return res.data
        } else {
          // this.$message.warning(res.msg)
          return []
        }
      } catch (error) {}
    },
    // // 上课地点 功能室
    // async getOrgSchoolRoomIdListFunc(key) {
    //   try {
    //     const res = await getSchoolRoomListApi(1, 10000, {
    //       sysSubjectDicId: key,
    //     })
    //     console.log('上课地点功能室', res)
    //     if (res.success) {
    //       return res.data
    //     } else {
    //       // this.$message.warning(res.msg)
    //       return []
    //     }
    //   } catch (error) {}
    // },
    // 上课地点
    async getSysOrgSchoolRoomIdListFunc(key) {
      try {
        const res = await getTeachPlaceRoom(this.crsOpenPlanId, key)
        console.log('上课地点功能室', res)
        if (res.success) {
          return res.data
        } else {
          // this.$message.warning(res.msg)
          return []
        }
      } catch (error) {}
    },
    // 添加排课/编辑排课 保存按钮
    savePlanBtn: debounce(function () {
      // 新增、编辑
      const apiMap = {
        add: manualPlan,
        update: updatePlan,
      }
      apiMap[this.dialogVisiblePlanType](
        this.crsOpenPlanId,
        this.dialogVisiblePlanData,
        this.dialogVisibleCrsOpenPlanLessonId
      )
        .then((res) => {
          if (res.success) {
            this.dialogVisibleIntelligentPlan = false
            this.page.pageIndex = 1
            this.getPagePlanFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    }),
    // 移除排课
    deleteBtn: debounce(function (row) {
      deletePlan(this.crsOpenPlanId, [row.crsOpenPlanLessonId])
        .then((res) => {
          if (res.success) {
            this.getPagePlanFunc()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    }, 300),
    // 分页查询排课结果
    getPagePlanFunc() {
      this.loadingRight = true
      getPagePlan(this.page.pageIndex, this.page.pageSize, this.crsOpenPlanId)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data
            this.page.totalSize = res.total
          } else {
            this.$message.error(res.msg)
          }
          this.loadingRight = false
        })
        .catch((err) => {})
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getPagePlanFunc()
    },
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.getPagePlanFunc()
    },
    saveBtn() {
      console.log('课程任务安排')
      this.$emit('saveBtnJump')
    },
    subjectTypeFilters(key) {
      return this.$store.state.public_data.sysSubjectDicList.find(
        (e) => e.value === key
      )?.name
    },
  },
  watch: {},
  filters: {
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY/MM/DD') : ''
    },
    // subjectTypeFilters(key) {
    //   this.$store.state.public_data.sysSubjectDicList.find(
    //     (e) => e.value === key
    //   )?.name
    // },
  },
}
</script>

<style lang="scss" scoped>
.taskBox {
  display: flex;
  padding-top: 10px;
  .courseList {
    width: 400px;
    height: 720px;
    box-sizing: border-box;
    border: 1px solid #c0c4cc;
    border-radius: 5px;
    padding: 10px 10px 0 10px;
    overflow-y: auto;
  }
  .taskList {
    width: calc(100% - 420px);
    margin-left: 20px;
    height: 100%;
    height: 720px;
    box-sizing: border-box;
    border: 1px solid #c0c4cc;
    border-radius: 5px;
    padding: 10px 10px 0 10px;
    overflow-y: auto;
  }
  .tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    height: 40px;
  }
}
</style>
